<template>
  <div id="masonry">
    <slot>
      <img src="https://place-hold.it/300x500" alt="" />
      <img src="https://place-hold.it/300" alt="" />
      <img src="https://place-hold.it/300x400" alt="" />
      <img src="https://place-hold.it/200x" alt="" />
    </slot>
  </div>
</template>

<style></style>

<script>
import MagicGrid from "magic-grid";

export default {
  name: "Masonry",
  mounted() {
    let magicGrid = new MagicGrid({
      container: "#masonry",
      static: true,
      animate: true
    });
    magicGrid.listen();
    magicGrid.positionItems();
    setTimeout(() => {
      magicGrid.listen();
      this.$forceUpdate();
    }, 400);
  }
};
</script>
