<template lang="html">
  <div id="about-page">
    <div class="h-300 pinot-noir__gradient">
      <div class="c c-white flex flex-col flex-aic flex-jcc h-100p">
        <h2 class="times ttu">MV Builders</h2>
        <h5 class="tac">
          Our improvements Directly Translate to our Experience
        </h5>
      </div>
    </div>
    <div class="c">
      <h2 class="c tac times ttu">
        MV Builders<br />
        <small class="mono">yours friendly Companion</small>
      </h2>
      <div class="c">
        <p class="para d-center p2">
          <span
            >We are widely known for our lucid way of choosing grounds for
            construction in a
            <span class="ttu fz-120p c-green">green</span> expansive
            environment. Our immense experience in apartment building with
            affordable price range attracts buyers from all parts of the world.
            We also provide clear title and legal opinion from eminent lawyers.
            Our floor plans are always VASTU compliant. Our impressive sense of
            architecture comprises of day light and ventilation optimization. We
            are well known for utilizing quality tools in our construction,
            employing skilled workers and delivering quality jobs to provide
            complete housing solutions.</span
          >
        </p>
      </div>
      <div class="row">
        <div class="col-md2">
          <h2 class="c tac">Our Growth</h2>
          <p class="para d-center">
            We believe in the best and the oldest means of marketing -
            <span class="nowrap-text fz-140p">Word of Mouth</span>. Our
            satisfied customers become members of the MV family and they can
            vouch for our quality and sophistication. We are proud to say that
            all the families are living in a friendly healthy ambience from the
            day of moving in to our homes.
          </p>
        </div>
        <div class="print-only-page-break"></div>
        <div class="col-md2 print-margin-top">
          <h2 class="c tac page-break">Our Quality</h2>
          <p class="para d-center">
            Our quality is constantly guaranteed by the quiet and experienced
            masonry teams, carpentry workers who have mastered the art of
            accurate finishing, immensely knowledgeable electricians, and
            plumbers from Orissa. Highly skilled and updated painters shine on
            our profile for a very long duration as we are constantly engaging
            the same team for our all ventures since the last couple of decades.
            Even after completion of various projects, petty maintenance works
            required by our esteemed clients are attended by our team without
            failure at their convenient time.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md2">
          <h2 class="c tac">Our Mantra</h2>
          <p class="para d-center">
            All our projects earlier and now are situated in a lush green
            environment and we have a policy to provide a healthy positive
            atmosphere for the clients, daylight optimized architecture,
            adequate water supply, sanitary, road facilities and in particular
            (good uninterupted ) electric supply. In addition all our projects
            each house will have a marked car parking slot, modular kitchenate
            for kitchens, builtin wardrobes, lofts and lockers with wooden
            interiors for the bedrooms.
          </p>
        </div>
        <div class="col-md2">
          <h2 class="c tac">Our Guides</h2>
          <p class="para d-center">
            We got professional guidance from our beloved architect having
            experienced and specialized in floor plans plotted as per vasthu
            specifications and a good support from our structural consultant
            having the details in support to our architects vision. We proudly
            confers all our apartment buildings so far sold and doing now are
            with 100 percent vasthu compliance.
          </p>
        </div>
      </div>
      <div id="proof" class="row flex-jcc page-break">
        <div class="col-md2">
          <h2 class="c tac">Future Proof</h2>
          <ul class="list-checked">
            <li>
              <span>Government approved flats with RERA registration</span>
            </li>
            <li>
              <span>Clear title and legal opinion from eminent lawyers</span>
            </li>
            <li hidden="true">lightning/storm earthquakeproof</li>
            <li>
              <span
                >Ground water level proof with cauvery and kollidam on either
                side</span
              >
            </li>
            <li>
              <span
                >Copious continuous water supply bore wells in addition to
                municipal water supply</span
              >
            </li>
            <li><span>Good infrastructure with 33 feet road</span></li>
            <li><span>Underground Drainage connected to main line</span></li>
            <li>
              <span
                >Connected lifestyle with nearby bus stops, railway station,
                schools, hospitals, temples, etc.,</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row c page-break">
      <div class="col-md2">
        <Card ctitle="Wide Living Space">
          <div slot="cbody">
            <img
              src="@/assets/frontview4.jpg"
              alt="front look of MV Vistara home"
              width="590"
              class="mw-100"
            />
            <h2 class="d-center tac">MV Vistara</h2>
            <!-- <p class="taj">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum numquam iusto enim magni sint necessitatibus placeat asperiores ipsa sit dolorem, fugiat aperiam eaque recusandae quibusdam quod debitis nesciunt nostrum veniam.</p> -->
          </div>
          <div slot="cfooter"></div>
        </Card>
      </div>
      <div class="col-md2">
        <Card ctitle="Tranquil Capacious Condos">
          <div slot="cbody">
            <img
              src="@/assets/mvtvasshta.jpeg"
              alt="front look of MV Tvasshta"
              width="590"
              class="mw-100"
            />
            <h2 class="d-center tac">MV Tvasshta</h2>
          </div>
          <div slot="cfooter"></div>
        </Card>
      </div>
    </div>
    <div class="row c page-break">
      <div class="col-md2">
        <Card ctitle="Pleasant Atmosphere">
          <div slot="cbody">
            <img
              src="@/assets/mvpranavm.jpg"
              alt="front look of MV pranavm home"
              width="590"
              class="mw-100"
            />
            <h2 class="d-center tac">MV Pranavm</h2>
            <!-- <p class="taj">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum numquam iusto enim magni sint necessitatibus placeat asperiores ipsa sit dolorem, fugiat aperiam eaque recusandae quibusdam quod debitis nesciunt nostrum veniam.</p> -->
          </div>
          <div slot="cfooter"></div>
        </Card>
      </div>
      <div class="col-md2 print-margin-top-medium">
        <Card ctitle="Green Environment">
          <div slot="cbody">
            <img
              src="@/assets/aparajitha.jpg"
              alt="front look of Aparajitha home"
              width="590"
              class="mw-100"
            />
            <h2 class="d-center tac">Aparajitha</h2>
            <!-- <p class="taj">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum numquam iusto enim magni sint necessitatibus placeat asperiores ipsa sit dolorem, fugiat aperiam eaque recusandae quibusdam quod debitis nesciunt nostrum veniam.</p> -->
          </div>
          <div slot="cfooter"></div>
        </Card>
      </div>
    </div>
    <div class="row c page-break">
      <div class="col-md2 print-margin-top-medium">
        <Card ctitle="Midst of Fresh Air">
          <div slot="cbody">
            <img
              src="@/assets/mvbrindhavan.jpg"
              alt="front look of MV Brindhavan home"
              width="590"
              class="mw-100"
            />
            <h2 class="d-center tac">MV Brindhavan</h2>
            <!-- <p class="taj">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum numquam iusto enim magni sint necessitatibus placeat asperiores ipsa sit dolorem, fugiat aperiam eaque recusandae quibusdam quod debitis nesciunt nostrum veniam.</p> -->
          </div>
          <div slot="cfooter"></div>
        </Card>
      </div>
      <div class="col-md2"></div>
    </div>
    <div class="pinot-noir__gradient page-break">
      <div class="c c-white hero-padding">
        <h2 id="founder" class="mt3">Founder</h2>
        <!-- <img src="https://source.unsplash.com/480x320?men" alt="an image of a man working, downloaded from unsplash.com"> -->
        <div class="row">
          <div class="col-md2 p3">
            <img
              src="@/assets/kmuralidharan.jpg"
              alt="Founder of MV Builders"
              width="740"
              class="mw-100 p2 grey-bordered"
            />
            <h5 class="mt2">K Muralidharan</h5>
            <!-- <p class="para">commenced career as early at 1987 experienced in various type of civil constructions. Having a rich exposure in apartment constructions both at coimbatore and trichy. Projects done so far have all the amenities in place and car parking each for all the flats in addition no hidden charges (ie) price includes everything. Having good work force for all types of civil constructions and interiors as we proudly say we have completed all our projects within the stipulated timeperiod. "</p> -->
          </div>
          <div class="col-md2 c print-margin-top">
            <h2 class="c">
              Decades of experience <br />
              <small>Pioneer in the housing field</small>
            </h2>
            <!-- <h3 class="c">Pioneer in the housing field</h3> -->
            <p class="para no-indent">
              <span class="ttu">K Muralidharan</span> commenced his construction
              career as early as 1987. He is a seasoned and skillful builder
              with abundant experience in multiple types of civil constructions.
              He has diverse vision owing to his rich exposure in apartment
              constructions at Coimbatore and Trichy, as well. Projects
              completed and delivered thus far have all the amenities in place
              and individual car parking slots for all the flats. We have a
              transparent business model which enables no hidden charges. The
              price quoted includes <span class="ttu">everything</span>.
              Professional work force that is competent in all types of civil
              constructions and interiors is the prime reason behind our
              impeccable execution and on-time delivery track record. We feel
              accomplished to claim that we have completed all our projects
              within the stipulated time period.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bg-cover h-bg" id="uc-bg1"></div>
    <div class="bg-cover h-bg" id="uc-bg2"></div>
    <div class="bg-cover h-bg" id="uc-bg3"></div> -->
    <!-- <div class="tac">
      <h2 class="mb3 mt3 pb3 pt3">
        <span class="times">MV Vistara</span>
        <span> is ready to be occupied.</span>
      </h2>
      <p class="para c d-center">
        Our Latest Project, MV VIstara is completed construction in the midst of
        <span class="fz-120p c-green nowrap-text">Lush Green Environment</span>.
        MV Vistara consists of 20 homes with wide parking area, 8 passenger lift
        facility, copious continuos water supply, etc.,
      </p>
    </div>
    <div class="c theme-2">
      <h1 class="c-red ml3 pl3 pulse-grow pb3 mb3">Hurry Up ! Buy Now</h1>
      <p class="fz-160p pl3 ml3">
        <span class="fz-160p c-red nowrap-text">Hurry up!</span>
        Already 17 people registered their home in MV Vistara and
        <span class="c-green">only 3 homes left</span> for sale. Visit the home
        and make it yours.
        <router-link @click.native="$scrollToTop" to="/contact"
          >Click Here for Contact details
        </router-link>
        or <a class="" href="tel:+919842430871">call +91 9842430871</a>
      </p>
    </div>
    <div class="row">
      <div class="col-md2">
        <img
          class="mw-100"
          src="../assets/frontview4.jpg"
          alt="front view of MV Vistara"
        />
        <h3>People occupying our home as they visit</h3>
      </div>
      <div class="col-md2">
        <img
          class="mw-100"
          src="../assets/roadtovistara2.jpg"
          alt="photo of beautiful lush green environment road which leads to MV Vistara"
        />
        <h3>Road to MV VIstara (midst of Lush Green Environment)</h3>
      </div>
    </div>
    <div class="row img-bp-gallery">
      <div class="col-md2">
        <img
          class="mw-100 img-bp"
          src="../assets/carparkwide3m.jpg"
          data-bp="/carparkwide3.jpg"
          alt="photo of wide car parking area"
        />
        <h3>wide area for car parking</h3>
      </div>
      <div class="col-md2">
        <img
          class="mw-100 img-bp"
          src="../assets/8passengerliftm.jpg"
          data-bp="/8passengerlift.jpg"
          alt="photo of 8 passenger lift and safety features/instructions"
        />
        <h3>tired? have luggage? skip stairs and use the lift</h3>
      </div>
    </div>
    <div class="row c page-break">
      <div class="col-md2">
        <Card ctitle="Fortified View">
          <div slot="cbody">
            <img
              src="@/assets/construction (3).jpg"
              alt="Fortified look of MV Vistara"
              class="mw-100"
            />
            <h3 class="d-center tac">wide and compact</h3>
          </div>
          <div slot="cfooter"></div>
        </Card>
      </div>
      <div class="col-md2 print-margin-top-medium">
        <Card ctitle="Mighty Roof">
          <div slot="cbody">
            <img
              src="@/assets/whitetilesroof2.jpg"
              alt="Top view of the MV Vistara roof which has white tiles"
              class="mw-100"
            />
            <h3 class="d-center tac">white tiles roof</h3>
          </div>
          <div slot="cfooter"></div>
        </Card>
      </div>
    </div> -->
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
var BigPicture = require("bigpicture");

export default {
  name: "about",
  components: {
    Card
  },
  mounted() {
    document.getElementById("about-page").onclick = function(e) {
      if (
        (e.target.tagName === "IMG" &&
          !e.target.classList.contains("img-bp")) ||
        e.target.className === "background-image"
      )
        BigPicture({
          el: e.target
        });
    };
    document.getElementsByClassName("img-bp-gallery")[0].onclick = function(e) {
      // console.log(e);
      if (e.target.tagName === "IMG" && e.target.classList.contains("img-bp"))
        BigPicture(
          {
            el: e.target,
            gallery: document.querySelectorAll(".img-bp"),
            loop: true
          },
          true
        );
    };
  }
};
</script>

<style lang="scss">
@import "node_modules/include-media/dist/include-media";
// #about-page p {
//   font-size: 1.12em;
// }

#about-page {
  // #uc-bg1 {
  //   background: url(" ") no-repeat center center;
  // }
  // #uc-bg2 {
  //   background: url("../assets/underconstruction (2).jpg") no-repeat center center;
  // }
  // #uc-bg3 {
  //   background: url("../assets/underconstruction (3).jpg") no-repeat center center;
  // }
  // .h-bg {
  //   min-height: 400px;
  //   max-height: 800px;
  //   @include media(">tablet") {
  //     min-height: 600px;
  //   }
  // }
  .row p {
    text-indent: 50px;
  }
}
</style>
