<template lang="html">
  <div id="project-page">
    <div class="h-300 purple-bliss__gradient">
      <div class="c c-white flex flex-col flex-aic flex-jcc h-100p">
        <h2 class="times ttu">MV Builders</h2>
        <h5 class="tac">Good Projects are the wealth of a Company</h5>
      </div>
    </div>
    <!-- <h1 class="c">List of Our Projects</h1> -->
    <!-- <h1 class="c">Our creations to make happy world</h1> -->
    <h1 class="c">Our creations for happy world</h1>
    <div class="p3">
      <p class="c">
        <span
          >All our projects are designed and constructed with a perfect blend of
          internal amenities and external infrastructure. In addition, we
          provide the members of the MV family, the pleasure of living in a
          <span class="fz-120p c-green nowrap-text"
            >Lush Green Environment</span
          >
          with well laid infrastructure amenities and 24x7 copious continuous
          essential services. An absolute combination of green and dwelling with
          peaceful, satisfied ambience assures a calm living.</span
        >
      </p>
    </div>
    <div class="c page-break">
      <h2 id="curpro" class="c">
        Current Project: <span>(Under Construction)</span>
      </h2>
      <div class="row c">
        <div class="col-md2">
          <img
            src="@/assets/mvm.jpg"
            data-bp="/mvm.jpg"
            alt="image of MV Maithri"
            width="705"
            class="mw-100 img-bp"
          />
          <a class="vam mt3" href="/M V MAITHRI BROUCHER.pdf"
            ><img
              width="50"
              height="60"
              src="@/assets/pdf_file.png"
              alt="pdf file icon"
            /><span class="vab fz-140p">Download MV MAITHRI Brochure</span></a
          >
        </div>
        <div class="col-md2">
          <h3>
            MV MAITHRI <small class="mono">compacted with benevolence</small>
          </h3>
          <p>
            Happy to launch our new project in Boologa Vaikundam, Srirangam,
            where everyone in the Universe have an ambition to live during their
            lifetime, especially during their retired livelihood. Our project is
            situated very near to Thayar Sannidhi which is helpful for easy
            navigation from home. MV MAITHRI provides a passenger lift with ARD
            (Automatic Rescue Device) facility to cater the needs of all walks
            of life. We assure MV MAITHRI will fulfill the need for benevolent
            moterly homes with a compact architecture surrounded by soulful
            temples and lovable people.
            <!-- ambitions of Srirangam-loving people spread all over the world. -->
          </p>
          <h4 class="print-margin-top">Location Advantages</h4>
          <ul class="list-checked">
            <li><span>Around 1km from Srirangam Thayar Sannadhi.</span></li>
            <li><span>Lush Green Environment</span></li>
            <li><span>2km from Bus Stand/Railway Station.</span></li>
            <li><span>3km from Government Butterfly Park.</span></li>
          </ul>
        </div>
      </div>
      <h2 class="hero-padding c">Current Progress</h2>
      <Masonry>
        <img
          class="img-bp"
          src="@/assets/uc/thumbs/uc (1).jpg"
          data-bp="/uc/uc (1).jpeg"
          alt="under construction photo of MV MAITHRI"
          width="276"
        />
        <img
          class="img-bp"
          src="@/assets/uc/thumbs/uc (2).jpg"
          data-bp="/uc/uc (2).jpeg"
          alt="under construction photo of MV MAITHRI"
          width="276"
        />
        <img
          class="img-bp"
          src="@/assets/uc/thumbs/uc (3).jpg"
          data-bp="/uc/uc (3).jpeg"
          alt="under construction photo of MV MAITHRI"
          width="276"
        />
        <img
          class="img-bp"
          src="@/assets/uc/thumbs/uc (4).jpg"
          data-bp="/uc/uc (4).jpeg"
          alt="under construction photo of MV MAITHRI"
          width="276"
        />
        <img
          class="img-bp"
          src="@/assets/uc/thumbs/uc (5).jpg"
          data-bp="/uc/uc (5).jpeg"
          alt="under construction photo of MV MAITHRI"
          width="276"
        />
        <img
          class="img-bp"
          src="@/assets/uc/thumbs/uc (6).jpg"
          data-bp="/uc/uc (6).jpeg"
          alt="under construction photo of MV MAITHRI"
          width="276"
        />
        <img
          class="img-bp"
          src="@/assets/uc/thumbs/uc (7).jpg"
          data-bp="/uc/uc (7).jpeg"
          alt="under construction photo of MV MAITHRI"
          width="276"
        />
        <img
          class="img-bp"
          src="@/assets/uc/thumbs/uc (8).jpg"
          data-bp="/uc/uc (8).jpeg"
          alt="under construction photo of MV MAITHRI"
          width="276"
        />
        <img
          class="img-bp"
          src="@/assets/uc/thumbs/uc (9).jpg"
          data-bp="/uc/uc (9).jpeg"
          alt="under construction photo of MV MAITHRI"
          width="276"
        />
      </Masonry>
      <!-- <div class="c theme-2">
        <h1 class="c-red ml3 pl3 pulse-grow pb3 mb3">Hurry Up ! Buy Now</h1>
        <p class="fz-160p pl3 ml3">
          <span class="fz-160p c-red nowrap-text">Hurry up!</span>
          Already 17 people registered their home in MV Vistara and
          <span class="c-green">only 3 homes left</span> for sale. Visit the
          home and make it yours.
          <router-link @click.native="$scrollToTop" to="/contact"
            >Click Here for Contact details
          </router-link>
          or <a class="" href="tel:+919842430871">call +91 9842430871</a>
        </p>
      </div> -->
      <h2 class="m3 p3">Floor Plans</h2>
      <h3 class="tac">North Facing Homes</h3>
      <div class="row c page-break">
        <div id="fs1" class="col-md2">
          <h3 class="tac">
            FS1/SS1
            <!-- (First/Second floor) -->
          </h3>
          <img
            src="@/assets/FINAL FS1_SS1_01.jpg"
            data-bp="/FINAL FS1_SS1_01.jpg"
            alt="image of floor plan design"
            width="500"
            class="mw-100 img-bp"
          />
        </div>
        <div class="col-md2">
          <h3 class="tac pb3">
            FS1/SS1
            <!-- (First/Second floor) -->
            (3D View)
          </h3>
          <img
            src="@/assets/FINAL FS1_SS1.jpg"
            data-bp="/FINAL FS1_SS1.jpg"
            alt="3D view of the floor plan"
            width="601"
            class="mw-100 img-bp"
          />
          <div class="md-grid grid-cols-2 gap-2 pt3 mt1">
            <h2 class="col-span-2" style="padding: .4rem;">Book now</h2>
            <a
              aria-label="Chat on WhatsApp"
              class="no-print inline-block p3"
              href="https://wa.me/919842430871"
            >
              <img
                alt="Chat on WhatsApp"
                class="mw-100"
                src="@/assets/WhatsAppButtonGreenLarge.png"
              />
            </a>
            <a class="inline-block p3" href="tel:+919842430871"
              ><img
                src="@/assets/cta-call.png"
                alt="smartphone ringing icon"
                class="mw-100"
            /></a>
          </div>
        </div>
      </div>
      <div class="row c page-break">
        <div id="fs2" class="col-md2">
          <h3 class="tac">
            FS2/SS2
            <!-- (First/Second floor) -->
          </h3>
          <img
            src="@/assets/FINAL FS2_SS2_01.jpg"
            data-bp="/FINAL FS2_SS2_01.jpg"
            alt="image of floor plan design"
            width="500"
            class="mw-100 img-bp"
          />
        </div>
        <div class="col-md2">
          <h3 class="tac pb3">
            FS2/SS2
            <!-- (First/Second floor) -->
            (3D View)
          </h3>
          <img
            src="@/assets/FINAL FS2_SS2.jpg"
            data-bp="/FINAL FS2_SS2.jpg"
            alt="3D view of the floor plan"
            width="533"
            class="mw-100 img-bp"
          />
          <div class="md-grid grid-cols-2 gap-2 pt3 mt1">
            <h2 class="col-span-2" style="padding: .4rem;">Book now</h2>
            <a
              aria-label="Chat on WhatsApp"
              class="no-print inline-block p3"
              href="https://wa.me/919842430871"
            >
              <img
                alt="Chat on WhatsApp"
                class="mw-100"
                src="@/assets/WhatsAppButtonGreenLarge.png"
              />
            </a>
            <a class="inline-block p3" href="tel:+919842430871"
              ><img
                src="@/assets/cta-call.png"
                alt="smartphone ringing icon"
                class="mw-100"
            /></a>
          </div>
        </div>
      </div>
      <div class="row c page-break">
        <div id="fs3" class="col-md2">
          <h3 class="tac">
            FS3/SS3
            <!-- (First/Second floor) -->
          </h3>
          <img
            src="@/assets/FINAL FS3_SS3_01.jpg"
            data-bp="/FINAL FS3_SS3_01.jpg"
            alt="image of floor plan design"
            width="500"
            class="mw-100 img-bp"
          />
        </div>
        <div class="col-md2">
          <h3 class="tac pb3">
            FS3/SS3
            <!-- (First/Second floor) -->
            (3D View)
          </h3>
          <img
            src="@/assets/FINAL FS3_SS3.jpg"
            data-bp="/FINAL FS3_SS3.jpg"
            alt="3D view of the floor plan"
            width="536"
            class="mw-100 img-bp"
          />
          <div class="md-grid grid-cols-2 gap-2 pt3 mt1">
            <h2 class="col-span-2" style="padding: .4rem;">Book now</h2>
            <a
              aria-label="Chat on WhatsApp"
              class="no-print inline-block p3"
              href="https://wa.me/919842430871"
            >
              <img
                alt="Chat on WhatsApp"
                class="mw-100"
                src="@/assets/WhatsAppButtonGreenLarge.png"
              />
            </a>
            <a class="inline-block p3" href="tel:+919842430871"
              ><img
                src="@/assets/cta-call.png"
                alt="smartphone ringing icon"
                class="mw-100"
            /></a>
          </div>
        </div>
      </div>
      <div class="row c page-break">
        <div id="fs4" class="col-md2">
          <h3 class="tac">
            FS4/SS4
            <!-- (First/Second floor) -->
          </h3>
          <img
            src="@/assets/FINAL FS4_SS4_01.jpg"
            data-bp="/FINAL FS4_SS4_01.jpg"
            alt="image of floor plan design"
            width="500"
            class="mw-100 img-bp"
          />
        </div>
        <div class="col-md2">
          <h3 class="tac pb3">
            FS4/SS4
            <!-- (First/Second floor) -->
            (3D View)
          </h3>
          <img
            src="@/assets/FINAL FS4_SS4.jpg"
            data-bp="/FINAL FS4_SS4.jpg"
            alt="3D view of the floor plan"
            width="519"
            class="mw-100 img-bp"
          />
          <div class="md-grid grid-cols-2 gap-2 pt3 mt1">
            <h2 class="col-span-2" style="padding: .4rem;">Book now</h2>
            <a
              aria-label="Chat on WhatsApp"
              class="no-print inline-block p3"
              href="https://wa.me/919842430871"
            >
              <img
                alt="Chat on WhatsApp"
                class="mw-100"
                src="@/assets/WhatsAppButtonGreenLarge.png"
              />
            </a>
            <a class="inline-block p3" href="tel:+919842430871"
              ><img
                src="@/assets/cta-call.png"
                alt="smartphone ringing icon"
                class="mw-100"
            /></a>
          </div>
        </div>
      </div>
      <h3 class="tac">South Facing Homes</h3>
      <div class="row c page-break">
        <div id="fn1" class="col-md2">
          <h3 class="tac">
            FN1/SN1
            <!-- (First/Second floor) -->
          </h3>
          <img
            src="@/assets/FINAL FN1_SNI_01.jpg"
            data-bp="/FINAL FN1_SNI_01.jpg"
            alt="image of floor plan design"
            width="500"
            class="mw-100 img-bp"
          />
        </div>
        <div class="col-md2">
          <h3 class="tac pb3">
            FN1/SN1
            <!-- (First/Second floor) -->
            (3D View)
          </h3>
          <img
            src="@/assets/FINAL FN1_SNI.jpg"
            data-bp="/FINAL FN1_SNI.jpg"
            alt="3D view of the floor plan"
            width="506"
            class="mw-100 img-bp"
          />
          <div class="md-grid grid-cols-2 gap-2 pt3 mt1">
            <h2 class="col-span-2" style="padding: .4rem;">Book now</h2>
            <a
              aria-label="Chat on WhatsApp"
              class="no-print inline-block p3"
              href="https://wa.me/919842430871"
            >
              <img
                alt="Chat on WhatsApp"
                class="mw-100"
                src="@/assets/WhatsAppButtonGreenLarge.png"
              />
            </a>
            <a class="inline-block p3" href="tel:+919842430871"
              ><img
                src="@/assets/cta-call.png"
                alt="smartphone ringing icon"
                class="mw-100"
            /></a>
          </div>
        </div>
      </div>
      <div class="row c page-break">
        <div id="fn2" class="col-md2">
          <h3 class="tac">
            FN2/SN2
            <!-- (First/Second floor) -->
          </h3>
          <img
            src="@/assets/FINAL FN2_SN2_01.jpg"
            data-bp="/FINAL FN2_SN2_01.jpg"
            alt="image of floor plan design"
            width="500"
            class="mw-100 img-bp"
          />
        </div>
        <div class="col-md2">
          <h3 class="tac pb3">
            FN2/SN2
            <!-- (First/Second floor) -->
            (3D View)
          </h3>
          <img
            src="@/assets/FINAL FN2_SN2.jpg"
            data-bp="/FINAL FN2_SN2.jpg"
            alt="3D view of the floor plan"
            width="434"
            class="mw-100 img-bp"
          />
          <div class="md-grid grid-cols-2 gap-2 pt3 mt1">
            <h2 class="col-span-2" style="padding: .4rem;">Book now</h2>
            <a
              aria-label="Chat on WhatsApp"
              class="no-print inline-block p3"
              href="https://wa.me/919842430871"
            >
              <img
                alt="Chat on WhatsApp"
                class="mw-100"
                src="@/assets/WhatsAppButtonGreenLarge.png"
              />
            </a>
            <a class="inline-block p3" href="tel:+919842430871"
              ><img
                src="@/assets/cta-call.png"
                alt="smartphone ringing icon"
                class="mw-100"
            /></a>
          </div>
        </div>
      </div>
      <div class="row c page-break">
        <div id="fn3" class="col-md2">
          <h3 class="tac">
            FN3/SN3
            <!-- (First/Second floor) -->
          </h3>
          <img
            src="@/assets/FINAL FN3_SN3_01.jpg"
            data-bp="/FINAL FN3_SN3_01.jpg"
            alt="image of floor plan design"
            width="500"
            class="mw-100 img-bp"
          />
        </div>
        <div class="col-md2">
          <h3 class="tac pb3">
            FN3/SN3
            <!-- (First/Second floor) -->
            (3D View)
          </h3>
          <img
            src="@/assets/FINAL FN3_SN3.jpg"
            data-bp="/FINAL FN3_SN3.jpg"
            alt="3D view of the floor plan"
            width="439"
            class="mw-100 img-bp"
          />
          <div class="md-grid grid-cols-2 gap-2 pt3 mt1">
            <h2 class="col-span-2" style="padding: .4rem;">Book now</h2>
            <a
              aria-label="Chat on WhatsApp"
              class="no-print inline-block p3"
              href="https://wa.me/919842430871"
            >
              <img
                alt="Chat on WhatsApp"
                class="mw-100"
                src="@/assets/WhatsAppButtonGreenLarge.png"
              />
            </a>
            <a class="inline-block p3" href="tel:+919842430871"
              ><img
                src="@/assets/cta-call.png"
                alt="smartphone ringing icon"
                class="mw-100"
            /></a>
          </div>
        </div>
      </div>
      <!-- <div class="col-md2">
          <h3 class="tac">Modular Kitchen</h3>
          <img
            src="@/assets/kitchenm.jpg"
            data-bp="/kitchen.jpg"
            alt="photo of constructed modular kitchen"
            class="mw-100 img-bp"
          />
        </div>
      </div>

      <div class="row c page-break">
        <div class="col-md2">
          <h3 class="tac">Hall Design (Ceiling)</h3>
          <img
            src="@/assets/hall.jpg"
            alt="photo of hall design with ceiling"
            class="mw-100"
          />
        </div>
        <div class="col-md2">
          <h3 class="tac">Bedroom Design (Wardrobe)</h3>
          <img
            src="@/assets/bedroom.jpg"
            alt="photo of bedroom design with wardrobe"
            class="mw-100"
          />
        </div>
      </div> -->

      <div class="row c page-break">
        <!-- <div class="col-md2"> -->
        <h3 class="tac mt3 pt3 pb3 mb3">Car Parking Plan</h3>
        <img
          src="@/assets/car_parkingm.webp"
          data-bp="/car_parking.jpg"
          alt="image of car parking design plan"
          class="mw-100 img-bp"
        />
        <!-- </div> -->
        <!-- <div class="col-md2">
          <h3 class="tac">Car Parking</h3>
          <img
            src="@/assets/carparkwide2m.jpg"
            data-bp="/carparkwide2.jpg"
            alt="photo of car parking"
            class="mw-100 img-bp"
          />
        </div> -->
      </div>
      <div class="row c">
        <h3 class="tac">Route Map</h3>
        <img
          src="@/assets/mapmvm.jpg"
          alt="image of navigation route map for MV Maithri"
          width="1264"
          class="mw-100 md-hide"
        />
        <iframe
          title="google map of MV Maithri"
          class="gmapvistara"
          src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d3918.2920565568234!2d78.68275382587682!3d10.86537675754501!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x3baaf677a73db341%3A0x9722303799dcab1b!2sSri%20Ranganayaki%20Thaayaar%20Sannadhi%2C%20North%20Uthrai%20Street%2C%20Sriramapuram%2C%20Tiruchirappalli%2C%20Tamil%20Nadu!3m2!1d10.8639689!2d78.6890763!4m5!1s0x3baaf7634fdc8d79%3A0xa59d0a9f8c07f8c1!2sgoogle%20map%20mv%20maithri!3m2!1d10.8679346!2d78.6811048!5e0!3m2!1sen!2sin!4v1691932857472!5m2!1sen!2sin"
          height="450"
          frameborder="0"
          style="border:0"
          allowfullscreen
        ></iframe>
      </div>
      <h2 id="compro" class="hero-margin pl2">Completed Projects</h2>
      <div class="md-grid grid-cols-2 grid-rows-2 hero-margin page-break">
        <div class="c">
          <h3 class="accordion-title mb3 mt3 tal">MV TVASSHTA</h3>
          <p>
            On completion of MV Vistara, launched MV Tvasshta an apartment
            complex with 5 units of condominiums at 1500sqft each. With ample
            car parking space AND daylight OPTIMISED ventilation for all the
            units. We got a very good response and
            <span class="fz-120p c-green"
              >all the units are sold out upon completion.</span
            >.
          </p>
          <div class="card p3 grey-bordered">
            <img
              src="@/assets/mvtvasshta.jpeg"
              alt="MV Tvasshta"
              width="589"
              class="mw-100"
            />
          </div>
        </div>
        <div class="row-span-1">
          <div class="card p3 mw-80 d-center grey-bordered">
            <img
              src="@/assets/mvtvasshtae.jpeg"
              alt="MV Tvasshta in night view"
              width="619"
              class="mw-100"
            />
            <h4 class="mb3 mt3">MV TVASSHTA</h4>
          </div>
        </div>
      </div>
      <div class="row hero-margin">
        <div class="col-md2 flex-asc">
          <h3 class="accordion-title mb3 mt3 tal">MV Vistara 2020</h3>
          <!-- <p class="pb3">
            Pristine and Picture perfect,
          </p> -->
          <p class="pb3">
            Pristine and Picture perfect, MV Vistara is on the alluring island
            of Srirangam amidst sacred water and sprawling greens -
            truly-one-of-a-kind destination.
          </p>

          <p class="pb3">
            These 2 BHK homes combine serenity, comfort and style in a perfect
            measure to offer a
            <span class="fz-120p c-green">
              holistic lifestyle and a prized possession to pass on to your
              loved ones.
            </span>
            This apartment complex features expansive open spaces, vast parking
            lots, beautiful and facility loaded homes. The MV Vistara gives its owners the flexibility to unwind in their own little paradise.
          </p>
          <!-- <p class="pb3">
            MV Vistara provides freedom to its owners to sit back and relax in their own private paradise.
          </p> -->
        </div>
        <div class="col-md2">
          <div class="card p3 grey-bordered">
            <img
              src="@/assets/frontview4.jpg"
              alt="front view of MV Vistara"
              width="620"
              class="mw-100"
            />
            <h4 class="pl3 mb3 mt3">MV Vistara</h4>
          </div>
        </div>
      </div>
      <div class="row hero-margin">
        <div class="col-md2">
          <div class="card p3 grey-bordered">
            <img
              src="@/assets/chaitanyakutir.jpeg"
              alt="Chaitanya Kutir"
              width="620"
              class="mw-100"
            />
            <h4 class="pl3 mb3 mt3">Chaitanya Kutir</h4>
          </div>
        </div>
        <div class="col-md2 flex-asc">
          <h3 class="accordion-title mb3 mt3 tal">Chaitanya Kutir 2017</h3>
          <p class="pb3">
            On seeing all our earlier projects, Shri. Venkatramanan from sharjah
            having a very good land available very near to the Srirangam Temple
            rampart wall.
          </p>
          <p class="pb3">
            with our enormous dedication and ultimate support from our architect
            and structural consultant and of course financial support from Shri.
            Venkatramanan.
          </p>
          <p class="pb3">
            The project is
            <span class="fz-120p c-green">completed within nine months</span>
            and yielded very positive results as the owner himself holds all the
            five units constructed and running a successful service apartment
            venture.
          </p>
          <p class="pb3">
            We proudly say upon all the above projects "Chaitany Kutir" gives
            immense support technically to us and financially to the owner.
          </p>
        </div>
      </div>
      <div class="row hero-margin page-break">
        <div class="col-md2 order__behind">
          <div class="card p3 grey-bordered">
            <img
              src="@/assets/mvdwaraka.jpg"
              alt="MV Dwaraka"
              width="620"
              class="mw-100"
            />
            <h4 class="mb3 mt3">MV Dwaraka</h4>
          </div>
        </div>
        <div class="col-md2 flex-asc">
          <h3 class="accordion-title mb3 mt3 tal">MV Dwaraka - 2016</h3>
          <p class="pb3">
            On getting the astonishing results from pranavm and brindhavan, we
            decided to have condominiums that too in a duplex house pattern (ie-
            hall, kitchen, bedroom and toiletries at one level and additional
            two bedrooms with balconies and toiletries at next level)
          </p>
          <p class="pb3">
            This proposal also clicked as having a very positive response as
            well as gives a delightful spatious ambience for the members of MV
            family.
          </p>
        </div>
      </div>
      <h3 class="pl2">Aparajitha and MV Brindhavan - 2015</h3>
      <div class="row hero-margin">
        <div class="col-md2 card grey-bordered">
          <img
            src="@/assets/aparajitha.jpg"
            alt="Aparajitha"
            width="620"
            class="mw-100"
          />
          <h4 class="mb3 mt3">Aparajitha</h4>
          <p>
            While doing brindhavan Project, an NRI wanted to have the project in
            a similar nature. We bought the plot and developed four large
            condominiums and at present all the houses are utilized as service
            apartments. We proudly say that aparajitha is the successful venture
            as it yields very good results for both builder and the customer.
          </p>
        </div>
        <div class="col-md2 card grey-bordered">
          <img
            src="@/assets/mvbrindhavan.jpg"
            alt="MV Brindhavan"
            width="620"
            class="mw-100"
          />
          <h4 class="mb3 mt3">MV Brindhavan</h4>
          <p>
            On completion of MV Pranavm, the next project brindhavan designed in
            such a way that the same amenities in a spatious floor space. That
            idea gives us pleasant results and comparitvely
            <span class="fz-120p c-green"
              >we sold all the units before completion</span
            >.
          </p>
        </div>
      </div>
      <div class="row hero-margin page-break">
        <div class="col-md2 card grey-bordered order__behind">
          <img
            src="@/assets/mvpranavm.jpg"
            alt="Chaitanya Kutir"
            width="620"
            class="mw-100"
          />
          <h4 class="pl3 mb3 mt3">MV Pranavm</h4>
        </div>
        <div class="col-md2 flex-asc">
          <h3 class="accordion-title mb3 mt3 tal">MV Pranavm - 2013</h3>
          <p class="pb3">
            pranavm defines the
            <span class="fz-140p c-green">commencement of a creation</span>
            wherein we found apt our first project named as pranavm.
          </p>
          <p class="pb3">
            It consists of only four compact units with a huge UDS area (almost
            equivalent to floor area)
          </p>
          <p class="pb3">
            All units have car parkings, modular kitchen and built-in wardrobes
            and cupboards.
          </p>
          <p class="pb3">
            Being the first of its kind, all four units sold within a span of a
            month upon completion.
          </p>
        </div>
      </div>
      <!-- <div class="accordion hero-margin">
        <div class="accordion-set tac ">
          <h3 class="accordion-title mb3 mt3 tal">Chaitanya Kutir 2017</h3>
          <div class="accordion-data h-show d-center card hero-margin">
            <img src="@/assets/chaitanyakutir.jpeg" alt="Chaitanya Kutir" class="mw-100">
            <h4 class="mb3 mt3">Chaitanya Kutir</h4>
          </div>
        </div>
        <div class="accordion-set tac ">
          <h3 class="accordion-title mb3 mt3 tal">MV Pranavm - 2016</h3>
          <div class="accordion-data d-center card hero-margin">
            <img src="@/assets/mvpranavm.jpg" alt="MV Pranavm" class="mw-100">
              <h4 class="mb3 mt3">MV Pranavm</h4>
          </div>
        </div>
        <div class="accordion-set tac ">
          <h3 class="accordion-title mb3 mt3 tal">MV Dwaraka - 2015</h3>
          <div class="accordion-data d-center card hero-margin">
            <img src="@/assets/mvdwaraka.jpg" alt="MV Dwaraka" class="mw-100">
              <h4 class="mb3 mt3">MV Dwaraka</h4>
          </div>
        </div>
        <div class="accordion-set tac ">
          <h3 class="accordion-title mb3 mt3 tal">Aparajitha and MV Brindhavan - 2015</h3>
          <div class="accordion-data d-center ">

            <div class="row">
              <div class="col-md2 card">
                <img src="@/assets/aparajitha.jpg" alt="Aparajitha" class="mw-100">
                  <h4 class="mb3 mt3">Aparajitha</h4>
              </div>
              <div class="col-md2 card hero-margin">
                <img src="@/assets/mvbrindhavan.jpg" alt="MV Brindhavan" class="mw-100">
                  <h4 class="mb3 mt3">MV Brindhavan</h4>
              </div>
            </div>

          </div>
        </div>
      </div> -->
      <!-- accordion layout -->
    </div>
  </div>
</template>

<script>
import Masonry from "@/components/Masonry.vue";

var BigPicture = require("bigpicture");

export default {
  name: "project",
  components: {
    Masonry
  },
  mounted() {
    // let accordionTriggers = document.querySelectorAll(
    //   ".accordion .accordion-set h3"
    // );
    //
    // for (let i = 0; i < accordionTriggers.length; i++) {
    //   accordionTriggers[i].addEventListener("click", function() {
    //     this.nextSibling.classList.toggle("h-show");
    //   });
    // }

    document.getElementById("project-page").onclick = function(e) {
      if (
        e.target.tagName === "IMG" &&
        !e.target.classList.contains("img-bp")
      ) {
        BigPicture({
          el: e.target
        });
      } else if (
        e.target.tagName === "IMG" &&
        e.target.classList.contains("img-bp")
      ) {
        BigPicture({
          el: e.target,
          gallery: document.querySelectorAll(".img-bp"),
          loop: true
        });
      }
    };
  }
};
</script>

<style lang="scss">
.mw-80 {
  max-width: 80%;
}

.fz-110p {
  font-size: 110%;
}

.order__behind {
  order: initial;
}

@media all and (max-width: 768px) {
  .order__behind {
    order: 1;
  }
}
</style>
